(function ($) {
  var WH = $(window).height();
  var WW = $(window).width();

  $(document).on("load, turbolinks:load", function () {
    
    // loading
    $(".with-loading").on("click", function(){
      $.LoadingOverlay("show");
    })

  });

})(jQuery);
