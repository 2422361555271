// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

// walkthrough items drag and drop sorting
require('jquery-ui-bundle');
require('packs/rails_sortable');

require("select2")
require("@nathanvda/cocoon");
require("jsoneditor")
require("gasparesganga-jquery-loading-overlay");
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("packs/mylime");

import "@fortawesome/fontawesome-free/js/all";

import 'select2/dist/css/select2.css';

require("bootstrap")

import JSONEditor from 'jsoneditor'

require("packs/mylime-geolocate");
require("packs/mylime-select");

// slick-carousel for media galleries
require('slick-carousel')
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

import getUserLocation from 'packs/mylime-geolocate';

window.JSONEditor = JSONEditor;
window.$ = $;
window.Cookies = require('js-cookie/src/js.cookie')
window.axios = require("axios")

import $ from 'jquery';
global.$ = jQuery;
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
document.addEventListener("turbolinks:load", function() {
  window.intlTelInput = intlTelInput
  window.JSONEditor = JSONEditor;
  window.$ = $;
  window.Cookies = require('js-cookie/src/js.cookie')
  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()
    $('.advanced_dropdown').select2()
  })
})
require("turbolinks").start()
