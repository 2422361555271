import select2 from 'select2';
import 'select2/dist/css/select2.css';

(function ($) {
    $(document).on("load, turbolinks:load", function () {
        $('.select2').each(function() {
            if ($(this).data('ajax-url')) {
                $(this).select2({
                    ajax: {
                        url: $(this).data('ajax-url'),
                        dataType: 'json'
                    }
                })
            } else {
                $(this).select2()
            }
        })

        $('#physical_tag_physically_taggable_type').on('change', function() {
            $("#physical_tag_physically_taggable_id").empty()
            var selected_type = $(this).data('selected-type')
            var selected_id = $(this).data('selected-id')
            $.ajax({
                url: $(this).data('other-ajax-url'),
                dataType: 'json',
                data: { type_klass: $(this).val(), selected_type: selected_type, selected_id: selected_id }
            }).done(function(res) {
                $("#physical_tag_physically_taggable_id").select2({data: res.options});
            });
        });
    
        $('#physical_tag_physically_taggable_type').trigger('change')
    });
  
  })(jQuery);
