window.getUserLocation = function(callback) {
    var user_position = {};

    if (navigator.geolocation) {
        var lat_lng = navigator.geolocation.getCurrentPosition(function(position){
        user_position.lat = position.coords.latitude; 
        user_position.lng = position.coords.longitude; 
        callback(user_position);
        });
    }
}

// usage:
// getUserLocation(function(lat_lng){
//     console.log(lat_lng)
// })
